import { computed, watch } from "vue";

import { useDoorsStore } from "@/stores/new/doors.store";
import { useOverdoorStore } from "@/stores/new/overdoor.store";
import { usePartitionStore } from "@/stores/new/partition.store";

import { DimensionValue } from "../domain/entities/dimensions";
import { PricingService } from "../services/pricing.service";

export const usePartitionGlassArea = () => {
    const pricingService = new PricingService();

    const partitionsStore = usePartitionStore();
    const doorsStore = useDoorsStore();
    const overdoorStore = useOverdoorStore();

    const partition = computed(() => partitionsStore.activePartitions);
    const door = computed(() => doorsStore.activeDoor);
    const overdoor = computed(() => overdoorStore.activeOverdoor);

    const partitionGlass = computed(() => partition.value?.getActiveGlass());
    const overdoorGlass = computed(() => overdoor.value?.getActiveGlass());
    const doorsGlass = computed(() => door.value?.getActiveGlassPartition());

    const deductDoorAndTransomGlassArea = () => {
        if (!partition.value) return;

        const partitionGlassDimensions = partition.value.dimensions.copy();
        const doorsGlassDimensions = doorsGlass.value?.dimensions.copy();
        const overdoorGlassDimensions = overdoorGlass.value?.dimensions.copy();

        const partitionArea =
            partitionGlassDimensions?.calculateArea().getValue() || 0;

        const doorGlassArea =
            doorsGlassDimensions?.area.toInches().getValue() || 0;

        const overdoorGlassArea =
            overdoorGlassDimensions?.area.toInches().getValue() || 0;

        const newPartitionArea =
            partitionArea - doorGlassArea - overdoorGlassArea;

        return new DimensionValue(newPartitionArea);
    };

    watch(
        () => [doorsGlass.value?.dimensions, overdoorGlass.value?.dimensions],
        () => {
            const newPartitionArea = deductDoorAndTransomGlassArea();

            console.log("newPartitionArea", newPartitionArea);

            if (!newPartitionArea) return;

            partitionGlass.value!.dimensions.area = newPartitionArea;
        },
        { deep: true },
    );

    watch(
        () => [
            partitionGlass.value?.price,
            partitionGlass.value?.dimensions.area,
        ],
        () => {
            if (!partitionGlass.value) return;

            const sum = pricingService.calculatePartitionGlassPrice();
            partitionGlass.value.update({ sum });
        },
        { immediate: true, deep: true },
    );

    watch(
        () => partitionGlass.value?.dimensions.area,
        () => {
            const updatedPartitionPrices = pricingService.getPartitionPrices();
            partition.value?.update({ price: updatedPartitionPrices });
        },
        { deep: true, immediate: true },
    );

    watch(
        () => partition.value?.price,
        () => {
            const updatedPartitionSum =
                pricingService.calculatePartitionPrice();

            partition.value?.update({ sum: updatedPartitionSum });
        },
        { deep: true },
    );
};
