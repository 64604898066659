import {
    Door,
    Estimate,
    Hardware,
    Overdoor,
    OverdoorMaterial,
    Partition,
    ReactiveComponentDimensions,
    Space,
} from "@/app/domain";
import { IDimensions, ComponentDimensions } from "@/app/@types/dimensions";
import {
    getFromDictByCode,
    getTitleFromDictByCode,
    useDictStore,
} from "../stores/dictStore";
import { createPinia, setActivePinia } from "pinia";
import { SummaryBlock, SummaryHeader, SummaryLine } from "../models/summary";
import {
    addTextToText,
    formatDateTime,
    formatDate,
    generateCode,
} from "../utils/common";

import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";
import { OutOfSquare } from "@/app/domain/entities/out-of-square";
import { OverdoorAsset } from "@/app/domain/entities/overdoor-asset";
import { Mounts } from "@/app/domain/entities/mounts";
import { MountHardware } from "@/app/domain/entities/hardware";
import { Overheads } from "@/app/domain/entities/overhead";
import { Delivery, DeliveryItem } from "@/app/domain/entities/delivery";
import { Extra, ExtraItem } from "@/app/domain/entities/extra";
import { computed, isRef } from "vue";
import { unwrapRef } from "@/utils/reactive";

setActivePinia(createPinia());
const dicts = useDictStore();
export class SummaryService {
    static blockFactory = (data: object = null): SummaryBlock => {
        return <SummaryBlock>{
            ...(<SummaryBlock>{
                code: generateCode(),
                type: null,
                objectType: null,
                objectCode: null,
                style: null,
                title: "",
                description: "",
                header: this.headerFactory(),
                footer: this.headerFactory(),
                lines: [],
                subBlocks: [],
            }),
            ...data,
        };
    };

    static lineFactory = (data: object = null): SummaryLine => {
        return <SummaryLine>{
            ...(<SummaryLine>{
                code: generateCode(),
                type: null,
                objectType: null,
                objectCode: null,
                style: null,
                title: null,
                qty: null,
                price: null,
                priceB2C: null,
                priceB2B: null,
                sum: null,
                sumB2C: null,
                sumB2B: null,
            }),
            ...data,
        };
    };

    static headerFactory = (data: object = null): SummaryHeader => {
        return <SummaryHeader>{
            ...this.lineFactory({
                ...{
                    type: "header",
                    priceTitle: null,
                    qtyTitle: null,
                    sumTitle: null,
                },
                ...data,
            }),
        };
    };

    static headerFactoryQtySum = (data: object = null): SummaryHeader => {
        return this.headerFactory({
            ...{
                title: "",
                qtyTitle: "Q-ty",
                priceTitle: "Price",
                sumTitle: "SUM",
            },
            ...data,
        });
    };

    static fromEstimate = (
        estimate: Estimate,
        spaces: Space[],
        delivery: Delivery,
    ): SummaryBlock => {
        const dictStore = useDictStore();
        const systemCode = computed(() => estimate.installedSystemCode);
        const systemTitle = computed(() =>
            getTitleFromDictByCode(
                dictStore.getSystemTypes(),
                systemCode.value,
            ),
        );

        const estimateSummary = this.blockFactory({
            type: "estimate",
            title:
                `System: ${systemTitle.value} - PRODUCT DESCRIPTION and AMOUNT` +
                " Track#: " +
                estimate.trackCode +
                " Estimate#: " +
                estimate.estimateNo,
            description:
                "DESCRIPTIONS FOR MANAGERS and copying to the HUB SPOT",
        });

        // add blocks
        estimateSummary.subBlocks?.push(this.getGeneralInfoBlock(estimate));

        // TODO: Refactor
        const activeSpace = spaces[0];
        const hasSpaces = spaces.length > 0;

        if (!hasSpaces) {
            return estimateSummary;
        }

        const extra = activeSpace.getExtra() as unknown as Extra;
        const overheads = activeSpace.getOverheads() as unknown as Overheads;

        for (const space of spaces) {
            const spaceSummary = this.blockFactory({
                type: "space",
                collapse: true,
                objectType: space.type,
                objectCode: space.id,
                title: space.title,
            });

            const partitionSummary = this.getStructure(space);

            if (partitionSummary) {
                spaceSummary.subBlocks?.push(...partitionSummary);
            }

            estimateSummary.subBlocks?.push(spaceSummary);
        }

        if (overheads && overheads.components.length > 0) {
            estimateSummary.subBlocks?.push(
                this.getOverheadStructure(overheads),
            );
        }

        if (delivery && delivery.components.length > 0) {
            estimateSummary.subBlocks?.push(
                this.getDeliveryStructure(estimate, delivery, extra),
            );
        }

        return estimateSummary;
    };

    static getGeneralInfoBlock = (info: Estimate): SummaryBlock => {
        let estimatorValue = "";
        if (typeof info.estimatorCode === "object") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            estimatorValue = info.estimatorCode?.code;
        } else {
            estimatorValue = info?.estimatorCode;
        }
        const infoBlock = this.blockFactory({
            type: "info",
            collapse: true,
            objectType: "info",
            objectCode: info?.code,
            title: "General Information",
            header: null,
            lines: [
                this.lineFactory({
                    type: "info",
                    title: "Estimate started",
                    value: formatDateTime(<any>info.started),
                }),
                this.lineFactory({
                    type: "info",
                    title: "Price Date",
                    value: formatDate(<number>info.priceDate),
                }),
                this.lineFactory({
                    type: "info",
                    title: "Usage",
                    value: getFromDictByCode(
                        dicts.getInstallationUsage(),
                        info.installationUsageCode,
                    )?.title,
                }),
                this.lineFactory({
                    type: "info",
                    title: "Finish (color)",
                    value: getFromDictByCode(
                        dicts.getFinishColors(),
                        info.colorCode,
                    )?.title,
                }),
                this.lineFactory({ type: "empty" }),
                this.lineFactory({
                    type: "info",
                    title: "First and Last name (in full)",
                    value: info?.client?.name,
                }),
                this.lineFactory({
                    type: "info",
                    title: "Business name (if applicable)",
                    value: info?.client?.businessName,
                }),
                this.lineFactory({
                    type: "info",
                    title: "Email",
                    value: info?.client?.email,
                }),
                this.lineFactory({
                    type: "info",
                    title: "Phone",
                    value: info?.client?.phone,
                }),
                this.lineFactory({
                    type: "info",
                    title: "Billing address",
                    value: info?.client?.shippingAddress,
                }),
                this.lineFactory({
                    type: "info",
                    title: "Shipping Address",
                    value:
                        addTextToText(
                            info?.shippingState ?? "",
                            info?.shippingZip ?? "",
                            ", ",
                        ) +
                        " " +
                        info?.client?.shippingAddress,
                }),
                this.lineFactory({ type: "empty" }),
                this.lineFactory({
                    type: "info",
                    title: "Delivery",
                    value: info?.delivery === "true" ? "yes" : "no",
                }),
                this.lineFactory({
                    type: "info",
                    title: "Installation",
                    value: info?.installation === "true" ? "yes" : "no",
                }),
                this.lineFactory({
                    type: "info",
                    title: "Manager",
                    value: info?.managerCode?.title,
                }),
                this.lineFactory({
                    type: "info",
                    title: "Estimator",
                    value: estimatorValue,
                }),
            ],
        });

        return infoBlock;
    };

    static getStructure = (space: Space) => {
        const partitions = space.getAllPartitions();

        if (!partitions) {
            // return this.blockFactory({
            //     type: "space",
            //     objectType: space.type,
            //     objectCode: space.code,
            //     title: space.title,
            //     header: this.headerFactoryQtySum({
            //         title: space.title,
            //     }),
            // });

            return null;
        }

        const summaryBlocks = partitions.map((partition) => {
            const summaryBlock = this.getPartitionStructure(partition) || [];

            const doors = (partition.getAllDoors() as unknown as Door[]) || [];

            const overdoors =
                (partition.getAllOverdoors() as unknown as Overdoor[]) || [];
            const mount = partition.getActiveMount() as unknown as Mounts;

            for (const door of doors) {
                summaryBlock.subBlocks?.push(this.getDoorStructure(door));
            }

            for (const overdoor of overdoors) {
                if (overdoor.isFiller()) {
                    summaryBlock.subBlocks?.push(
                        this.getFillerStructure(overdoor),
                    );
                } else if (overdoor.isTransom()) {
                    summaryBlock.subBlocks?.push(
                        this.getTransomStructure(overdoor),
                    );
                } else {
                    summaryBlock.subBlocks?.push(
                        this.blockFactory({
                            type: "filler",
                            code: overdoor.id,
                            title: "Filler and transom: none",
                            header: this.headerFactoryQtySum({
                                title: "Filler and transom: none",
                            }),
                        }),
                    );
                }
            }

            if (mount && mount.components.length > 0) {
                summaryBlock.subBlocks?.push(this.getMountsStructure(mount));
            }

            return summaryBlock;
        });

        return summaryBlocks;
    };

    static getPartitionStructure = (partition: Partition): SummaryBlock => {
        const glass = partition.getActiveGlass();
        const glassThickness = partition.getActiveGlassThinkness();

        const block = this.blockFactory({
            type: "partition",
            title: partition.title,
            objectType: partition.type,
            objectCode: partition.id,
            header: this.headerFactoryQtySum({
                title: partition.title,
                objectType: partition.type,
                objectCode: partition.id,
            }),
            lines: [
                this.lineFactory({
                    type: "head",
                    title: [
                        "Partition",
                        dimensionsToText(partition.dimensions),
                        `(${partition.dimensions.area
                            .toSquareFt()
                            .format()} sq.ft)`,
                    ].join(" "),
                    price: partition.price
                        .getSystemPrice(CostDealType)
                        .getValue(), // цену partition берем из суммы элементов
                    priceB2C: partition.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: partition.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    qty: partition.qty,
                    sum: partition.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: partition.sum
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    sumB2B: partition.sum
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                }),

                this.lineFactory({
                    type: "qtysum",
                    title: [
                        getFromDictByCode(
                            dicts.getGlassSorts(),
                            glass?.structureType,
                        )?.title,
                        getFromDictByCode(
                            dicts.getGlassThickness(),
                            glassThickness?.structureType,
                        )?.title,
                    ].join(" "),
                    price: glass?.price.getSystemPrice(CostDealType).getValue(), // цену partition берем из суммы элементов
                    priceB2C: glass?.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: glass?.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    qty: glass?.dimensions.area.toSquareFt().format(),
                    sum: glass?.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: glass?.sum.getSystemPrice(B2CDealType).getValue(),
                    sumB2B: glass?.sum.getSystemPrice(B2BDealType).getValue(),
                }),
            ],
        });

        const outOfSquare = partition.getActiveOutOfSquare()!;

        if (outOfSquare.qty > 0) {
            block.lines.push(
                this.lineFactory({
                    type: "qtysum",
                    title: "Out of square",
                    qty: outOfSquare.qty,
                    price: outOfSquare.price
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    priceB2C: outOfSquare.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: outOfSquare.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    sum: outOfSquare.sum
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    sumB2C: outOfSquare.sum
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    sumB2B: outOfSquare.sum
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                }),
            );
        }
        return block;
    };

    static getDoorStructure = (door: Door): SummaryBlock => {
        const glass = door.getActiveGlassPartition();
        const glassThinkness = door.getActiveGlassThinkness();

        const hardwares = door.getActiveHardwares() as unknown as Hardware[];
        const nonEmptyHardwares = hardwares.filter(
            (hardware) => !hardware.isEmpty(),
        );

        const doorType = computed(
            () =>
                getFromDictByCode(dicts.getDoorTypes(), door.structureType)
                    ?.title,
        );

        // no door
        if (door.isDoorNone()) {
            return this.blockFactory({
                type: "door",
                title: "DOOR",
                objectType: door.type,
                objectCode: door.id,
                lines: [
                    this.lineFactory({
                        type: "head",
                        title: "Door none",
                        price: 0,
                        priceB2C: 0,
                        priceB2B: 0,
                        qty: 0,
                        sum: 0,
                        sumB2C: 0,
                        sumB2B: 0,
                    }),
                ],
            });
        }

        // any door
        const doorBlock: SummaryBlock = this.blockFactory({
            type: "door",
            title: "DOOR",
            objectType: door.type,
            objectCode: door.id,
            header: null,
            lines: [
                this.lineFactory({
                    type: "head",
                    title: [
                        doorType.value,
                        dimensionsToText(door.dimensions),
                        `(${door.dimensions.area.toSquareFt().format()} sq.ft)`,
                    ].join(" "),
                    price: door?.price.getSystemPrice(CostDealType).getValue(),
                    priceB2C: door?.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: door?.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    qty: door.qty,
                    sum: door?.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: door?.sum.getSystemPrice(B2CDealType).getValue(),
                    sumB2B: door?.sum.getSystemPrice(B2BDealType).getValue(),
                }),

                this.lineFactory({
                    type: "qtysum",
                    title: [
                        getFromDictByCode(
                            dicts.getGlassSorts(),
                            glass?.structureType,
                        )?.title,
                        getFromDictByCode(
                            dicts.getGlassThickness(),
                            glassThinkness?.structureType,
                        )?.title,
                    ].join(" "),
                    price: glass?.price.getSystemPrice(CostDealType).getValue(),
                    priceB2C: glass?.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: glass?.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    qty: glass?.dimensions.area.toSquareFt().format(),
                    sum: glass?.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: glass?.sum.getSystemPrice(B2CDealType).getValue(),
                    sumB2B: glass?.sum.getSystemPrice(B2BDealType).getValue(),
                }),
            ],
        });

        for (const hardware of nonEmptyHardwares) {
            doorBlock.lines.push(
                this.lineFactory({
                    type: "qtysum",
                    title: [
                        getFromDictByCode(
                            dicts.getHardwareValues(
                                unwrapRef(hardware.typeCode),
                            ),
                            hardware.structureType,
                        )?.title,
                    ].join(" "),
                    qty: hardware.qty,
                    price: hardware.price
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    priceB2C: hardware.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: hardware.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    sum: hardware.sum.getSystemPrice(B2BDealType).getValue(),
                    sumB2C: hardware.sum.getSystemPrice(B2CDealType).getValue(),
                    sumB2B: hardware.sum.getSystemPrice(B2BDealType).getValue(),
                }),
            );
        }
        return doorBlock;
    };

    static getFillerStructure = (overdoor: Overdoor): SummaryBlock => {
        const material =
            overdoor.getMaterial()[0] as unknown as OverdoorMaterial;
        const outOfSquare = overdoor.getOutOfSquare() as unknown as OutOfSquare;
        const asset = overdoor.getAsset() as unknown as OverdoorAsset;

        const block: SummaryBlock = this.blockFactory({
            type: "filler",
            title: "Filler",
            objectType: overdoor.type,
            objectCode: overdoor.id,
            header: null,
            lines: [
                this.lineFactory({
                    type: "head",
                    title: [
                        "Filler",
                        dimensionsToText(overdoor.dimensions),
                        `(${overdoor.dimensions.area
                            .toSquareFt()
                            .format()} sq.ft)`,
                    ].join(" "),
                }),
                this.lineFactory({
                    type: "qtysum",
                    title: [
                        "Material:",
                        getFromDictByCode(
                            dicts.getAluminumMaterialTypes(),
                            material?.structureType,
                        )?.title,
                    ].join(" "),
                    price: material?.price
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    priceB2C: material?.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: material?.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    qty: material?.qty,
                    sum: material?.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: material?.sum
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    sumB2B: material?.sum
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                }),
                this.lineFactory({
                    type: "qtysum",
                    title: "Out of square",
                    price: outOfSquare.price
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    priceB2C: outOfSquare.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: outOfSquare.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    qty: outOfSquare.qty,
                    sum: outOfSquare.sum
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    sumB2C: outOfSquare.sum
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    sumB2B: outOfSquare.sum
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                }),
                this.lineFactory({
                    type: "qtysum",
                    title: "Assets",
                    price: asset.price.getSystemPrice(CostDealType).getValue(),
                    priceB2C: asset.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: asset.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    qty: asset.qty,
                    sum: asset.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: asset.sum.getSystemPrice(B2CDealType).getValue(),
                    sumB2B: asset.sum.getSystemPrice(B2BDealType).getValue(),
                }),
            ],
        });

        return block;
    };

    static getTransomStructure = (transom: Overdoor): SummaryBlock => {
        const glass = transom.getGlass();
        const glassThickness = transom.getGlassThinkness();

        const block: SummaryBlock = this.blockFactory({
            type: "transom",
            title: "Transom",
            objectType: transom.type,
            objectCode: transom.id,
            header: null,
            lines: [
                this.lineFactory({
                    type: "head",
                    title: [
                        "Transom",
                        dimensionsToText(transom.dimensions),
                        `(${transom.dimensions.area
                            .toSquareFt()
                            .format()} sq.ft)`,
                    ].join(" "),
                    price: transom.price
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    priceB2C: transom.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: transom.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    qty: transom.qty,
                    sum: transom.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: transom.sum.getSystemPrice(B2CDealType).getValue(),
                    sumB2B: transom.sum.getSystemPrice(B2BDealType).getValue(),
                }),
            ],
        });

        if (glass && glassThickness) {
            block.lines.push(
                this.lineFactory({
                    type: "qtysum",
                    title: [
                        getFromDictByCode(
                            dicts.getGlassSorts(),
                            glass.structureType,
                        )?.title,
                        getFromDictByCode(
                            dicts.getGlassThickness(),
                            glassThickness.structureType,
                        )?.title,
                    ].join(" "),
                    price: glass.price.getSystemPrice(CostDealType).getValue(),
                    priceB2C: glass.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: glass.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    qty: glass.dimensions.area.toSquareFt().format(),
                    sum: glass.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: glass.sum.getSystemPrice(B2CDealType).getValue(),
                    sumB2B: glass.sum.getSystemPrice(B2BDealType).getValue(),
                }),
            );
        }

        return block;
    };

    static getMountsStructure = (mounts: Mounts): SummaryBlock => {
        const hardwares = mounts.getHardwares() as unknown as MountHardware[];

        const block: SummaryBlock = this.blockFactory({
            type: "mounts",
            title: "CHANEL, TRACKS, PROFILES",
            objectType: mounts.type,
            objectCode: mounts.id,
            header: null,
            lines: [
                this.lineFactory({
                    type: "head",
                    title: ["CHANEL, TRACKS, PROFILES"].join(" "),
                    price: mounts.price.getSystemPrice(CostDealType).getValue(),
                    priceB2C: "",
                    priceB2B: "",
                    qty: "",
                    sum: mounts.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: mounts.sum.getSystemPrice(B2CDealType).getValue(),
                    sumB2B: mounts.sum.getSystemPrice(B2BDealType).getValue(),
                }),
            ],
        });
        for (const hardware of hardwares) {
            block.lines.push(
                this.lineFactory({
                    type: "qtysum",
                    title: [
                        getFromDictByCode(
                            dicts.getHardwareTypes("mounts"),
                            hardware.typeCode,
                        )?.title + ":",
                        getFromDictByCode(
                            dicts.getHardwareValues(hardware.typeCode),
                            hardware.structureType,
                        )?.title,
                    ].join(" "),
                    qty: hardware.qty,
                    price: hardware.price
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    priceB2C: hardware.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: hardware.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    sum: hardware.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: hardware.sum.getSystemPrice(B2CDealType).getValue(),
                    sumB2B: hardware.sum.getSystemPrice(B2BDealType).getValue(),
                }),
            );
        }
        return block;
    };

    static getOverheadStructure = (overheads: Overheads): SummaryBlock => {
        const items = overheads.getOverheadItems() as unknown as OverheadItem[];

        const block: SummaryBlock = this.blockFactory({
            type: "overhead",
            collapse: true,
            title: "Overhead costs",
            objectType: overheads.type,
            header: this.headerFactoryQtySum({
                title: "",
                objectType: overheads.type,
            }),
            lines: [],
        });
        for (const item of items) {
            block.lines.push(
                this.lineFactory({
                    type: "head",
                    title: getFromDictByCode(
                        dicts.getAdditionalCosts(),
                        item.typeCode,
                    )?.title,
                    price: item.price.getSystemPrice(CostDealType).getValue(),
                    priceB2C: item.price.getSystemPrice(B2CDealType).getValue(),
                    priceB2B: item.price.getSystemPrice(B2BDealType).getValue(),
                    qty: item.qty,
                    sum: item.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: item.sum.getSystemPrice(B2CDealType).getValue(),
                    sumB2B: item.sum.getSystemPrice(B2BDealType).getValue(),
                }),
            );
        }
        return block;
    };

    static getDeliveryStructure = (
        estimate: Estimate,
        delivery: Delivery,
        extra: Extra,
    ): SummaryBlock => {
        const deliveryItems =
            (delivery?.getDeliveryItems() as unknown as DeliveryItem[]) || [];
        const extraItems =
            (extra?.getExtraItems() as unknown as ExtraItem[]) || [];

        const block: SummaryBlock = this.blockFactory({
            type: "delivery",
            collapse: true,
            objectType: delivery.type,
            title: "Delivery and installation",
            header: this.headerFactoryQtySum({
                title: "",
                objectType: delivery.type,
            }),
            lines: [
                this.lineFactory({
                    type: "keyval",
                    title: "Shipping zip",
                    value: estimate.shippingZip,
                }),
                this.lineFactory({
                    type: "keyval",
                    title: "Shipping state",
                    value: estimate.shippingState,
                }),
            ],
        });

        for (const item of deliveryItems) {
            block.lines.push(
                this.lineFactory({
                    type: "head",
                    title: [
                        getFromDictByCode(
                            dicts.getAdditionalCostValues(item.typeCode),
                            item.structureType,
                        )?.title,
                    ].join(" "),
                    qty: item.qty,
                    price: item.price.getSystemPrice(CostDealType).getValue(),
                    priceB2C: item.price.getSystemPrice(B2CDealType).getValue(),
                    priceB2B: item.price.getSystemPrice(B2BDealType).getValue(),
                    sum: item.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: item.sum.getSystemPrice(B2CDealType).getValue(),
                    sumB2B: item.sum.getSystemPrice(B2BDealType).getValue(),
                }),
            );
        }

        block.lines.push(this.lineFactory({ type: "empty" }));

        for (const extraItem of extraItems) {
            block.lines.push(
                this.lineFactory({
                    type: "qtysum",
                    title: getFromDictByCode(
                        dicts.getHardwareValues(),
                        extraItem.structureType,
                    )?.title,
                    qty: extraItem.qty,
                    price: extraItem.price
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    priceB2C: extraItem.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: extraItem.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),

                    sum: extraItem.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: extraItem.sum
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    sumB2B: extraItem.sum
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                }),
            );
        }

        return block;
    };
}

const needRenderDimensionUnit = (unitValue: number): boolean => {
    return ![0, undefined, null].includes(unitValue);
};

export const oneDimensionToText = (dimension: IDimensions): string => {
    const parts: any[] = [];

    const ft = unwrapRef(dimension.ft);
    const inch = unwrapRef(dimension.inch);
    const fractionDividend = unwrapRef(dimension.fractionDividend);
    const fractionDivisor = unwrapRef(dimension.fractionDivisor);

    if (ft + inch + fractionDividend + fractionDivisor === 0) {
        return "0";
    }
    if (needRenderDimensionUnit(ft)) {
        parts.push(ft?.toString() + "'");
    }
    if (needRenderDimensionUnit(inch)) {
        parts.push(
            inch?.toString() + (fractionDividend + fractionDivisor ? '"' : ""),
        );
    }
    if (needRenderDimensionUnit(fractionDividend + fractionDivisor)) {
        parts.push(
            fractionDividend?.toString() +
                "/" +
                fractionDivisor?.toString() +
                '"',
        );
    }
    return parts.join(" ");
};

export const dimensionsToText = (dimensions: ComponentDimensions): string => {
    return (
        oneDimensionToText(dimensions.width) +
        "W x " +
        oneDimensionToText(dimensions.height) +
        "H"
    );
};

export const calculateClearArea = (
    dimensions: ReactiveComponentDimensions,
): number => {
    const heightInches =
        dimensions.height.ft.value * 12 + dimensions.height.inch.value;
    const widthInches =
        dimensions.width.ft.value * 12 + dimensions.width.inch.value;

    const areaInSquareInches = widthInches * heightInches;
    const areaInSquareFeet = areaInSquareInches / 144;

    return parseFloat(areaInSquareFeet.toFixed(2));
};
