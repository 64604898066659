<template>
  <div class="step">
    <div class="step__left">
      <div class="step__left-scroll">
        <div class="step__left-wrapper">
          <div :class="{ 'cursor-not-allowed': isEstimateReadonly }">
            <div
              class="step__left-content"
              :class="{ 'content-readonly': isEstimateReadonly }"
            >
              <EstimateTitleField />
              <StepName />
              <StepProgress />
              <CalculationStep />
            </div>
            <QuestionControl
              :typeAddButton="typeAddButton"
              @onChangeStep="changeStep"
              @action="doAction"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="step__right">
      <div class="step__right-scroll">
        <EstimateSummary ref="infoEstimate" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useNotification } from "@kyvg/vue3-notification";

import { EstimateSummary } from "@/modules/summary";

import StepProgress from "@/components/StepProgress.vue";
import StepName from "@/components/StepName.vue";
import QuestionControl from "@/components/QuestionControl.vue";
import CalculationStep from "@/components/CalculationStep.vue";

import { useEstimateStore } from "@/stores/estimateStore";
import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { useDoorsStore } from "@/stores/new/doors.store";
import { useUiStore } from "@/stores/uiStore";

import { EstimateTitleField } from "@/modules/estimate";
import { useOverdoorStore } from "@/stores/new/overdoor.store";
import { useActionsStrore } from "@/stores/new/actions.store";
import { useStepsStore } from "@/stores/new/steps.store";

const { notify } = useNotification();

const route = useRoute();
const router = useRouter();

const estimateStore = useEstimateStore();

const newEstimateStore = useNewEstimateStore();
const doorsStore = useDoorsStore();
const overdoorsStore = useOverdoorStore();

const actionsStore = useActionsStrore();
const stepsStore = useStepsStore();

const isEstimateReadonly = newEstimateStore.getEstimateReadonly();

let typeAddButton: string = "Transom";

onMounted(() => {
  if (route.params.id === 0 || route.params.id > 8) {
    router.push(`/notFound`);
  }
  window.addEventListener("beforeunload", leaving);
});

onBeforeUnmount(() => {
  window.removeEventListener("onbeforeunload", leaving);
});

function leaving(event) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (import.meta.env.MODE !== "development") {
    event.preventDefault();
    return null;
  }
}

function doAction(actionName: string) {
  switch (actionName) {
    case "addSpace":
      actionsStore.addAnotherSpace();
      stepsStore.goToStep("2");
      break;
    case "addPartition":
      actionsStore.addAnotherPartition();
      stepsStore.goToStep("2");
      break;
    case "addDoor":
      doorsStore.addDoor();
      break;
    case "addFiller":
      overdoorsStore.addOverdoor();
      break;
    case "saveDraft":
      actionsStore.saveAsDraft();
      newEstimateStore.save();
      notify({
        title: "Save as a draft",
      });
      break;
    case "saveOfficial":
      newEstimateStore.save("OFFICIAL");
      router.push({ name: "calculationTable" });
      break;
    case "saveQuote":
      newEstimateStore.save("QUOTE");
      router.push({ name: "calculationTable" });
      break;
    case "saveOrder":
      newEstimateStore.save("ORDER");
      router.push({ name: "calculationTable" });
      break;
    case "saveDone":
      newEstimateStore.save("DONE");
      router.push({ name: "calculationTable" });
      break;

    case "cloneEstimate":
      estimateStore.cloneEstimate();
      break;
    default:
      console.error("invalid action", actionName);
  }
}

function changeStep(direction: string) {
  switch (direction) {
    case "prev":
      stepsStore.prev();
      break;
    case "next":
      stepsStore.next();
      break;
    default:
      console.error("invalid step direction", direction);
  }
}
</script>

<style scoped>
.step__right {
  padding: 40px 30px;
  height: 99vh;
  overflow: auto;
}

.step__right-scroll {
  width: 100%;
  min-width: 720px;
}

.step__left-scroll {
  overflow-x: auto;
  background: #f3f3f6;
}

.step__left-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 20px 45px 20px;
  height: 99vh;
  /* width: 720px; */
  overflow-y: auto;
}

.step {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

.step__left {
  background: #f3f3f6;
  height: 100%;
}

.step__left-input {
  color: rgba(64, 77, 99, 1);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d2d8e3;
  width: 100%;
}

/* .step__left-title {
  color: #404d63;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.step__left-title span {
  font-weight: 400;
}

.step__left-title-spase {
  padding-right: 15px;
  padding-left: 10px;
} */

.step__left-progressbar {
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  height: 6px;
  margin-bottom: 20px;
}

.step__left-progressbar div {
  border-radius: 10px;
  background-color: #2491eb;
  height: 100%;
  transition: 0.5s ease;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
</style>
