<template>
  <div class="step__block-field" v-if="activeParition">
    <div class="step__block-field-block">
      <h3>page Total:</h3>
      <span class="step__block-field-price">
        {{ activeParition.sum.getSystemPrice(dealTypeCode).format() }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PricingService } from "@/app/services/pricing.service";
import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { usePartitionStore } from "@/stores/new/partition.store";
import { computed, watch } from "vue";

const pricingService = new PricingService();

const partitionStore = usePartitionStore();
const estimateStore = useNewEstimateStore();

const dealTypeCode = computed(() => estimateStore.estimate.dealTypeCode);
const activeParition = computed(() => partitionStore.activePartitions);

watch(
  () => [activeParition.value?.components],
  () => {
    const price = pricingService.getPartitionPrices();
    activeParition.value?.update({ price });
  },
  { deep: true },
);

watch(
  () => [activeParition.value?.price],
  () => {
    const sum = pricingService.calculatePartitionPrice();
    activeParition.value?.update({ sum });
  },
  { deep: true },
);
</script>

<style lang="scss" scoped>
.step__block-field {
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  border-bottom: 1px solid #d2d8e3;
  display: flex;
}

.step__block-field-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field h3 {
  color: #404d63;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
</style>
